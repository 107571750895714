<template>
  <div class="Header" ref="Header">
    <div class="menu" @click="openexhibit" v-if="menu"></div>
    <div class="menu" @click="openexhibits" v-if="menus"></div>
    <div class="pencil" @click="openmap" v-if="pencil"><span v-if="pencilStart"></span></div>
    <div class="lamp" @click="openlamp" v-if="lamp">
      <div class="MaxData" :style="MaxData"></div>
      <img src="../assets/image/header/lamp.png">
    </div>
    <div class="logo" :style="loges"></div>
    <div class="number" :style="numers">{{ creationNumber }}</div>
    <div class="avatar" @click="openUser" v-if="avatar">
      <img src="../assets/image/header/right.png">
    </div>
  </div>
</template>

<script>
import {Dialog} from "vant";
import {initHome} from "../api/home";


export default {
  data() {
    return {
      userInfo: {},
      lamp: false,
      avatar: false,
      pencil: false,
      menu: false,
      menus: false,
      logo: false,
      pencilStart:false,
      paintingStart: false,
      creationNumber: 0,
      loges: 'opacity:1;',
      numers: 'opacity:0;transition: 2s;',
      speed: '',
      MaxData: '',
      Max: 0,
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$store.state.paintingStart == true) {
        this.paintingStart = true
      }
    }, 500)
    this.init()
    this.numers = 'opacity:0'
    this.initHomeData()
    this.da()
  },
  beforeDestroy() {
    localStorage.setItem("store", JSON.stringify(this.$store.state));
    clearInterval(this.logoStart)
    clearInterval(this.logoStarts)
  },
  methods: {
    initHomeData(){
      initHome().then(res => {
        let creationNumber = res.creationNumber
        this.creationNumbers = creationNumber
        this.creationNumber = this.getNunber(creationNumber)
      })
    },
    da() {
      // let MaxData = 'height:60%'
      this.CodeTime = setInterval(() => {
        let Max = this.Max
        Max = Max + 5
        if (Max == 100) {
          Max = 0
          this.Max = Max
          this.MaxData = `height:${Max}%`
        } else {
          this.Max = Max
          this.MaxData = `height:${Max}%`
        }
      }, 60)
    },
    NumberTol() {
      return this.creationNumber
    },
    getNunber(str) {
      if (str < 10) {
        return '0000' + str
      } else if (str < 100) {
        return '000' + str
      } else if (str < 1000) {
        return '00' + str
      } else if (str < 10000) {
        return '0' + str
      }
    },
    init() {
      let userInfo = this.$store.state.userInfo
      // alert(userInfo.Name+'1')
      if (userInfo.Name) {
        this.userInfo = userInfo
      } else {
        let sum = JSON.parse(localStorage.getItem("user"))
        // alert(sum.Name+'2')
        this.userInfo = sum
      }
      setTimeout(() => {
        this.loges = 'opacity:1;transition: 2s;'
      }, 500)

      this.logoStart = setInterval(() => {
        if (this.loges == 'opacity:0;transition: 2s;') {
          this.loges = 'opacity:1;transition: 2s;'
          this.numers = 'opacity:0;transition: 2s;'
        } else {
          this.loges = 'opacity:0;transition: 2s;'
          this.numers = 'opacity:1;transition: 2s;'
        }
      }, 5000)


      this.logoStarts = setInterval(() => {
        if (this.pencilStart == true) {
          this.pencilStart = false
        } else {
          this.pencilStart = true
        }
      }, 500)

      // pencilStart



      switch (this.$route.path) {
        case "/zju/user":
          this.menu = true
          this.lamp = true
          break;
        case "/zju/list":
          this.avatar = true
          this.pencil = true
          break;
        case "/zju/map":
          this.menu = true
          this.avatar = true
          break;
        case "/zju/exhibit":
          this.avatar = true
          this.pencil = true
          this.lamp = true
          break;
        case "/zju/create":
          this.menus = true
          break;
        case "/zju/createPc":
          this.menus = true
          break;
        case "/zju/introduce":
          this.menu = true
          this.avatar = true
          this.lamp = true
          break;
        case "/zju/lamp":
          this.menu = true
          this.avatar = true
          break;
      }
    },
    openexhibits() {
      Dialog.confirm({
        message: '是否放弃该画布并返回？',
      }).then(() => {
        this.$router.push('/zju/exhibit')

      }).catch(() => {
      });
    },
    openlamp() {
      this.$router.push('/zju/lamp')
    },
    offsetHeight() {
      return this.$refs.Header.offsetHeight
    },
    openexhibit() {
      this.$router.push('/zju/exhibit')
    },
    openUser() {
      this.$router.push('/zju/user')
    },
    openmap() {
      if ( this.creationNumbers>=1250){
        Dialog.alert({
          message: '很抱歉，已达到创作上限\n活动已结束',
        }).then(() => {
          // on close
        });
      }else {
        this.$store.state.paintingStart = false
        this.$router.push('/zju/introduce')
      }
    }
  }
}
</script>

<style scoped lang="scss">
@font-face {
  font-family: intext;
  src: url('https://hz-mayi.nos-eastchina1.126.net/2021-11-6/VonwaonBitmap-12px.ttf');
}

.Header {
  width: 100%;
  height: 60px;
  position: relative;
  background-color: #FFFFFF;

  .lamp {
    width: 27px;
    height: 22px;
    position: absolute;
    right: 50px;
    top: 50%;
    margin-top: -11px;
    background-color: #bababa;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    .MaxData {
      position: absolute;
      width: 100%;
      height: 0;
      left: 0;
      bottom: 0;
      background-color: #f55958;
    }
  }

  .menu {
    width: 22px;
    height: 22px;
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -11px;
    background-image: url("../assets/image/header/meun.png");
    background-size: 100% 100%;
  }

  .pencil {
    width: 22px;
    height: 22px;
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -11px;
    background-image: url("../assets/image/header/feather.png");
    background-size: 100% 100%;

    span {
      width: 10px;
      height: 10px;
      background-color: #d80000;
      position: absolute;
      display: block;
      right: 0;
      bottom: 0;
      border-radius: 50px;
    }
  }

  .logo {
    width: 140px;
    height: 22px;
    position: absolute;
    left: 50%;
    margin-left: -70px;
    top: 50%;
    margin-top: -11px;
    background-image: url("../assets/image/header/logo126.png");
    background-size: 100% 100%;

    //background-position: center center;
    //background-repeat: no-repeat;
  }


  .number {
    width: 138px;
    height: 26px;
    position: absolute;
    left: 50%;
    margin-left: -69px;
    top: 50%;
    margin-top: -13px;
    text-align: center;
    color: #1989fa;
    line-height: 26px;
    font-family: intext;
    font-size: 28px;
  }

  .avatar {
    width: 29px;
    height: 29px;
    //background-color: rgba(216, 216, 216, 1);
    border-radius: 50%;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -14.5px;
    //background-image: url("../assets/image/login/avatar.png");
    //background-size: 100% 100%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

}
</style>
